
.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global(.ant-btn-link) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    svg {
      width: 20px;
      height: 20px;
    }
    .print {
      color: #999999;
      &:hover {
        color: fuchsia;
      }
    }
  }
  :global(.ant-btn-link[disabled]) {
    opacity: 0.5;
  }
}